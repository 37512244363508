
import React, { useState } from 'react';
import bcrypt from 'bcryptjs';
import './Login.css';

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Hash the password before sending it to the server
        const salt = bcrypt.genSaltSync(10);
        const hashedPassword = bcrypt.hashSync(password, salt);

        // TODO: Send the hashed password and username to your backend
        try {
            const response = await fetch('YOUR_BACKEND_API_ENDPOINT', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    username,
                    password: hashedPassword,
                }),
            });

            const data = await response.json();

            // Handle the response from your server here (e.g., save a token, navigate to another page, etc.)
        } catch (error) {
            console.error('Error:', error);
        }
    }

    return (
        <div className="login-container">
            <form onSubmit={handleSubmit}>
                <div className="login-inputs">
                    <input
                        type="text"
                        placeholder="Username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </div>
                <div className="login-inputs">
                    <input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                <button type="submit">Login</button>
            </form>
        </div>
    );
}

export default Login;
