import React from 'react';
import '../App.css';
import Login from '../components/Login';

function Home() {
  return (
    <div className="home-background">
      <div className="center-container">
        <h1 className="blue-text">
          <br/>
          <span className="large-text">WealthHex.io</span>
          <br/>
          <a href="http://whisperaiml.com">Click for whisperaiml.com</a>
          <br/>
          <a href="http://chatwithmywebsite.com">Click for chatwithmywebsite.com</a>
          <br/>
          <a href="http://embedgpt.chatwithmywebsite.com">Click for EmbedGPT</a>
          <br/>
          Coming soon howdoi.pro and howdoi.app
          <br/><br/>
          Please login to continue:
          <Login />
        </h1>
      </div>
    </div>
  );
}

export default Home;

